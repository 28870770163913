// extracted by mini-css-extract-plugin
export var a = "blog-module--a--detaL";
export var activeLink = "blog-module--active-link--NS8KQ";
export var aside = "blog-module--aside--64cr4";
export var categoryLink = "blog-module--category-link--lqwz9";
export var container = "blog-module--container--sUZ3k";
export var h1 = "blog-module--h1--P711q";
export var li = "blog-module--li--MKmSH";
export var pagination = "blog-module--pagination--BK-q-";
export var postsList = "blog-module--posts-list--2FEqB";
export var ul = "blog-module--ul--EaZDA";